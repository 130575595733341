/*
Author:      Zachary Thomas
Created:     5/14/2021
Modified:    6/4/2021

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.navbar-custom {
  background-color: var(--primary-background);
}

.navbar-custom .navbar-brand {
  color: var(--title);
}

.navbar-custom .navbar-brand:hover {
  color: var(--title-hover);
}