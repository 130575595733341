/*
Author:      Zachary Thomas
Created:     5/14/2021
Modified:    10/8/2021

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

$primary: #007bff;
$secondary: #6c757d;
$success: #28a745;
$danger: #dc3545;
$warning: #ffc107;
$info: #17a2b8;
$light: #f8f9fa;
$dark: #343a40;
$white: #fff;

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";