/*
Author:      Zachary Thomas
Created:     5/20/2022
Modified:    5/20/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.code-block {
  border-style: solid;
  border-width: 1px;
  border-color: #909090;
  border-radius: 0.5rem;
  background-color: #d3d3d3;
}

.validate-modal-container {
  .btn {
    font-size: 0.75rem;

    @include media-breakpoint-up(sm) {
      font-size: 1rem;
    }
  }
}